import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, A11y } from "swiper";

import React, { useState, useEffect } from "react";

import "swiper/css/bundle";
import "swiper/css/effect-fade";

import ImageWrapper from "./ImageWrapper";
import { EffectFade } from "swiper";

import CmsRow from "./CmsRow";

function CmsSwiperIntro({ component, next_row, page_id }) {
  component.options.time = 5000;
  component.options.speed = 1000;

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <div id={`${component.id ?? ""}`}>
      {isClient ? 
        <Swiper
          id={`swiperintro_${component.id ?? ""}`}
          modules={[Autoplay, EffectFade, A11y]}
          spaceBetween={0}
          autoplay={{
            delay:2000,
            disableOnInteraction: false,
            stopOnLastSlide: true,
          }}
          speed={0}
          effect={"fade"}
          autoHeight={true}
          allowTouchMove={false}
          slidesPerView={1}
          // loop={false}
        >
          {component.options.slides.map(function (slide, i) {
            // var imageStyle = typeof slide.image.url != "" ? { backgroundImage: `url(${slide.image.url})` } : {};

            return (
              <SwiperSlide
                key={i}
                className={`slide relative`}
                // style={imageStyle}
              >
                <ImageWrapper
                  alt={` `}
                  src={slide.image.url}
                  blur={slide.image.urlp}
                  priority={i == 0}
                  // layout="fill"
                  // objectFit="cover"
                  fill={true}
                  // width={slide.image.width}
                  // height={slide.image.height}
                  quality={100}
                />
                <div className="caption">
                  <div className="container mx-auto">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: slide.caption,
                      }}
                    />
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
          <SwiperSlide className={`slide fake`} >
            {next_row !== null ? 
              <CmsRow row={next_row} next_row={null} page_id={page_id} />
              : ''
            }
          </SwiperSlide>
        </Swiper>
        : ''
      }
    </div>
  );
}

export default CmsSwiperIntro;
