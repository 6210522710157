import React from "react";
import ImageWrapper from "./ImageWrapper";
import Link from "next/link";
import { useRouter } from "next/router";

function Employee({ row, component, card }) {
//   const router = useRouter();
//   let baseUrl =
//     router.asPath.split("/page").join("") + "/" + component.options.slug;

  const silouette =
    card.gender == "M"
      ? "silouette-uomo-200x300.jpg"
      : "silouette-donna-200x300.jpg";
  return (
    <div>
      <div className="card">
        <div className="cardContent">
          <div className="flex items-center justify-center lg:justify-start gap-2">
            <div className="image">
              <ImageWrapper
                alt={` `}
                src={`/images/${silouette}`}
                width={30}
                height={50}
              />
            </div>
            <h6>{card.name}</h6>
          </div>
          <div className="cardCaption">
            {card.role ? 
                <div>
                    {card.role ?? ''}
                </div>
            : ''}
            {card.countries.length > 0 ? 
                <div>
                    ({card.countries.join(', ')})
                </div>
            : ''}

            <div className="card-links">
                {card.phone ? 
                    <Link className="card-link" href={`tel:${card.phone}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M347.1 24.6c7.7-18.6 28-28.5 47.4-23.2l88 24C499.9 30.2 512 46 512 64c0 247.4-200.6 448-448 448c-18 0-33.8-12.1-38.6-29.5l-24-88c-5.3-19.4 4.6-39.7 23.2-47.4l96-40c16.3-6.8 35.2-2.1 46.3 11.6L207.3 368c70.4-33.3 127.4-90.3 160.7-160.7L318.7 167c-13.7-11.2-18.4-30-11.6-46.3l40-96z"/></svg>
                        {card.phone}
                    </Link>
                : ''}
                {card.email ? 
                    <Link className="card-link" href={`mailto:${card.email}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>
                        {card.email}
                    </Link>
                : ''}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Employee;
